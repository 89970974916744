<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="项目名称" name="name"></c-form-item>
					<c-form-item type="input" label="监理单位" name="jldw_name"></c-form-item>
					<c-form-item type="input" label="施工单位" name="yzdw_name"></c-form-item>
					<c-form-item type="datetime" label="申请时间" start-name="start_date" end-name="end_date"></c-form-item>
				</c-form>
			</m-search>
			
			<c-table ref="table">
				<c-table-column
					label="状态"
					width="130"
				>
					<template v-slot="{data}">
						<span v-if="personState[data.state]" :style="{'color': personState[data.state].color}">{{personState[data.state].name}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="项目名称"
					name="project_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="施工单位(中标企业)"
					name="jldw_name"
					width="300"
				></c-table-column>
				
				<c-table-column
					label="监理单位"
					name="yzdw_name"
					width="300"
				></c-table-column>
				
				<c-table-column
					label="项目经理"
					name="xmjl_xm"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="技术总工"
					name="jszg_xm"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="安全员"
					name="aqy_xm"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="施工员"
					name="sgy_xm"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					name="create_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="80"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button v-if="data.state == 2" @click="navigateTo('/person_change_detail', {id: data.id})">审批</c-table-button>
						<c-table-button v-else @click="navigateTo('/person_change_detail', {id: data.id})">详情</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
	</page>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		name: 'person_change',
		
		computed: {
			...mapGetters(['personState'])
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data) {
				this.$refs.table.load({
					url: '/project/ry/list',
					data
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>